import React from "react";
import {
  Routes,
  Route,
  useNavigate,
} from "react-router-dom";

import "./App.css";
import PlayerListItem from "./components/player-list-item/PlayerListItem";
import PlayersList from "./components/PlayersList/PlayersList";
import PlayerInfo from "./components/PlayerInfo/PlayerInfo";
import Stats from "./components/Stats/Stats";
import OtherStats from "./components/Stats/OtherStats";
import Games from "./components/Games/Games";
import GameInfo from "./components/Gameinfo/GameInfo";

import * as firestore from "firebase/firestore";

import { db } from "./firebase.js";
import { useEffect, useState } from "react";

import { styled, useTheme } from "@mui/material/styles";
import {Box,Paper, Drawer, CssBaseline,
  AppBar as MuiAppBar,
  Toolbar,
  List,
  Typography,
  Divider,
  Collapse,
  IconButton,
  Icon,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import {
  Menu as MenuIcon,
  ChevronLeft as ChevronLeftIcon,
  ExpandLess,
  ExpandMore,
  ChevronRight as ChevronRightIcon,
} from "@mui/icons-material/";
import { orderBy } from "firebase/firestore";

const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));
//

function App() {
  const navigate = useNavigate();
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [openStatsList, setOpenStatsList] = useState(true);

 

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const [loading, setLoading] = useState(true);
  const [players, setPlayers] = useState([]);

  const playersFromFirebase = [];
  function getPlayersFromFirebase() {
    firestore
      .getDocs(
        firestore.query(
          firestore.collection(db, "players"),
          orderBy("amountPaidFor", "desc")
        )
      )
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          playersFromFirebase.push({ ...doc.data(), key: doc.id });
        });

        setPlayers(playersFromFirebase);
        setLoading(false);
      });
  }

  useEffect(() => {
    getPlayersFromFirebase();
  }, []);
  function handleNavigation(path) {
    navigate(`/${path}`);
    setOpen(false);
  }

  return (
    <div className="App">
      {/* <Router> */}
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar position="fixed" open={open}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{ mr: 2, ...(open && { display: "none" }) }}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap component="div">
              Grellige 7
            </Typography>
          </Toolbar>
        </AppBar>

        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              boxSizing: "border-box",
            },
          }}
          variant="persistent"
          anchor="left"
          open={open}
        >
          <DrawerHeader>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "ltr" ? (
                <ChevronLeftIcon />
              ) : (
                <ChevronRightIcon />
              )}
            </IconButton>
          </DrawerHeader>
          <Divider />
          <List>
            {[
              { name: "Drank", icon: "sports_bar", path: "" },
              { name: "Wedstrijden", icon: "sports", path: "games" },
            ].map((obj, index) => (
              <ListItem
                key={obj.name}
                disablePadding
                onClick={() => handleNavigation(obj.path)}
              >
                <ListItemButton>
                  <ListItemIcon>
                    <Icon>{obj.icon}</Icon>
                  </ListItemIcon>
                  <ListItemText primary={obj.name} />
                </ListItemButton>
              </ListItem>
            ))}

            <ListItem
              key="stats2"
              disablePadding
              onClick={() => setOpenStatsList(!openStatsList)}
            >
              <ListItemButton>
                <ListItemIcon>
                  <Icon>bar_chart</Icon>
                </ListItemIcon>
                <ListItemText primary="Stats" />
                {openStatsList ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
            </ListItem>
            <Collapse in={openStatsList} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem
                  key={"stats-games"}
                  onClick={() => handleNavigation("stats/games")}
                >
                  <ListItemButton>
                    <ListItemIcon>
                      <Icon>bar_chart</Icon>
                    </ListItemIcon>
                    <ListItemText primary={"Wedstrijden"} />
                  </ListItemButton>
                </ListItem>
                <ListItem
                  key={"stats-other"}
                  onClick={() => handleNavigation("stats/other")}
                >
                  <ListItemButton>
                    <ListItemIcon>
                      <Icon>bar_chart</Icon>
                    </ListItemIcon>
                    <ListItemText primary={"Andere"} />
                  </ListItemButton>
                </ListItem>
              </List>
            </Collapse>
            <Divider />
            <ListItem
              key={"players"}
              disablePadding
              onClick={() => handleNavigation("players")}
            >
              <ListItemButton>
                <ListItemIcon>
                  <Icon>list</Icon>
                </ListItemIcon>
                <ListItemText primary={"Spelers"} />
              </ListItemButton>
            </ListItem>
          </List>
        </Drawer>

        {/* <Sidebar /> */}
        <Routes>
          <Route
            path="/"
            element={
              !loading && (
                <Main open={open}>
                  <DrawerHeader />
                  {/* {console.log(players)} */}
                  {players.map((player) => (
                    <PlayerListItem player={player} key={player.key} />
                  ))}
                  {players.length < 1 && (
                    <Paper>No players found. Please add 1</Paper>
                  )}
                </Main>
              )
            }
          ></Route>
          <Route
            path="/player/:key"
            element={
              !loading && (
                <Main open={open}>
                  <DrawerHeader />
                  <PlayerInfo players={players} getPlayersFromFirebase={getPlayersFromFirebase}/>
                </Main>
              )
            }
          ></Route>
          <Route
            path="/games"
            element={
              !loading && (
                <Main open={open}>
                  <DrawerHeader />
                  <Games players={players} />
                </Main>
              )
            }
          ></Route>
          <Route
            path="/games/:key"
            element={
              !loading && (
                <Main open={open}>
                  <DrawerHeader />
                  <GameInfo players={players} />
                </Main>
              )
            }
          ></Route>
          <Route
            path="/stats/games"
            element={
              !loading && (
                <Main open={open}>
                  <DrawerHeader />
                  <Stats players={players} />
                </Main>
              )
            }
          ></Route>
          <Route
            path="/stats/other"
            element={
              !loading && (
                <Main open={open}>
                  <DrawerHeader />
                  <OtherStats 
                  players={players} 
                  getPlayersFromFirebase={getPlayersFromFirebase}
                  />
                </Main>
              )
            }
          ></Route>
          <Route
            path="/players"
            element={
              !loading && (
                <Main open={open}>
                  <DrawerHeader />
                  <PlayersList
                    players={players}
                    getPlayersFromFirebase={getPlayersFromFirebase}
                  />
                </Main>
              )
            }
          ></Route>
        </Routes>
        {/* <Main open={open}>
        <DrawerHeader />
      {loading && <h1>Loading</h1> }
     </Main> */}
      </Box>
      {/* </Router> */}
    </div>
  );
}

export default App;
//
