import { React, useState, useEffect } from "react";
import {
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Card,
  CircularProgress,
  TextField,
  Button,
  Divider,
} from "@mui/material/";
import {
  ArrowBackSharp,
  ExpandMore as ExpandMoreIcon,
  SportsSoccer,
  EmojiEvents,
  Accessible,
} from "@mui/icons-material";
import CardIcon from "../customIcons/CardIcon";
import SoccerBoot from "../customIcons/SoccerBoot";

import { useParams, useNavigate } from "react-router-dom";

import {
  getDoc,
  doc,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../firebase";

function GameInfo({ players }) {
  console.log(players)
  let sortedPlayers = players.sort((a, b) => (a.name > b.name) ? 1 : -1)

  let urlKey = useParams().key;
  const [gameInfo, setGameInfo] = useState({});
  const [loading, setLoading] = useState(true);
  const [formValues, setFormValues] = useState({
    homeScore: null,
    awayScore: null,
  });

  const navigate = useNavigate();

  async function getGameInfo(urlKey) {
    const docRef = doc(db, "games", urlKey);
    const docSnap = await getDoc(docRef);
    setGameInfo(docSnap.data());
    setLoading(false);
  }
  function getPlayerName(playerKey) {
    let curPlayer = sortedPlayers.find((player) => player.key === playerKey);
    return curPlayer.name;
  }
  function nominatePlayer(type, playerKey, urlKey) {
    db.collection("games")
      .doc(urlKey)
      .update({ [type]: playerKey });
    getGameInfo(urlKey);
  }
  async function addPlayerToMatchInfo(type, playerKey, urlKey) {
    const docRef = doc(db, "games", urlKey);
    const docSnap = await getDoc(docRef);
    let array = docSnap.data()[type];
    array.push(playerKey);
    await updateDoc(docRef, {
      [type]: array,
    });
    getGameInfo(urlKey);
  }
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    console.log(name, value);
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    db.collection("games")
      .doc(urlKey)
      .update({
        homeScore: formValues.homeScore ? formValues.homeScore : null,
        awayScore: formValues.awayScore ? formValues.awayScore : null,
      });
    getGameInfo(urlKey);
  };
  function ScoreForm() {
    return (
      <form className="text-center" onSubmit={handleSubmit}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
          }}
        >
          <TextField
            id="homeScore-input"
            name="homeScore"
            label="Thuisploeg"
            type="number"
            value={formValues.homeScore ? formValues.homeScore : ""}
            onChange={handleInputChange}
            sx={{ m: 2 }}
            size="small"
          />
          -
          <TextField
            id="awayScore-input"
            name="awayScore"
            label="Uitploeg"
            type="number"
            value={formValues.awayScore ? formValues.awayScore : ""}
            onChange={handleInputChange}
            sx={{ m: 2 }}
            size="small"
          />
        </div>

        <div>
          <Button variant="contained" type="submit">
            Save
          </Button>
        </div>
      </form>
    );
  }
  function playerCardSingleChoice(urlKey, player, type) {
    return (
      <div className="flex align-center" key={player.key}>
        <div className="player-info flex align-center">{player.name}</div>
        <div>
          <IconButton
            sx={{ justifyContent: "flex-start" }}
            className="w100"
            onClick={() => nominatePlayer(type, player.key, urlKey)}
          >
            {type === "mvp" ? <EmojiEvents /> : <Accessible />}
          </IconButton>
        </div>
      </div>
    );
  }

  function playerCard(urlKey, player) {
    return (
      <div className="flex align-center justify-around" key={player.key}>
        <div>{player.name}</div>
        <div>
          <IconButton
            sx={{ justifyContent: "flex-start" }}
            className="w100"
            onClick={() =>
              addPlayerToMatchInfo("goalScorers", player.key, urlKey)
            }
          >
            <SportsSoccer />
          </IconButton>
        </div>
        <div>
          <IconButton
            sx={{ justifyContent: "flex-start" }}
            className="w100"
            onClick={() =>
              addPlayerToMatchInfo("assistMakers", player.key, urlKey)
            }
          >
            <SoccerBoot />
          </IconButton>
        </div>
        <div>
          <IconButton
            sx={{ justifyContent: "flex-start" }}
            className="w100"
            onClick={() =>
              addPlayerToMatchInfo("yellowCards", player.key, urlKey)
            }
          >
            <CardIcon color={"yellow"} />
          </IconButton>
        </div>
        <div>
          <IconButton
            sx={{ justifyContent: "flex-start" }}
            className="w100"
            onClick={() => addPlayerToMatchInfo("redCards", player.key, urlKey)}
          >
            <CardIcon color={"red"} />
          </IconButton>
        </div>
      </div>
    );
  }

  useEffect(() => {
    getGameInfo(urlKey);
  }, []);
  return (
    <div className="">
      <IconButton
        sx={{ justifyContent: "flex-start" }}
        className="w100"
        onClick={() => navigate(-1)}
      >
        <ArrowBackSharp />
      </IconButton>
      {loading && <CircularProgress />}

      {!loading && (
        <Card className="m1-0">
            <Typography className="flex justify-around" variant="h6" noWrap component="div">
            {gameInfo.homeTeam} {gameInfo.homeScore} - {gameInfo.awayScore} {gameInfo.awayTeam} 
          </Typography>
          <div className="flex justify-between">
            {gameInfo.mvp && <div>
              <EmojiEvents sx={{ mr: "10px", ml: "10px" }} />{" "}
              {getPlayerName(gameInfo.mvp)}
            </div>}
            {gameInfo.lvp && <div>
              {getPlayerName(gameInfo.lvp)}
              <Accessible sx={{ mr: "10px", ml: "10px" }} />
            </div>}
          </div>
           {(gameInfo.mvp || gameInfo.lvp) && <Divider />}
          <div className="flex justify-between">
            <div className="p05">
              {gameInfo.goalScorers.map((goalscorer, index) => (
                <div className="flex align-center p05" key={index}>
                  <SportsSoccer sx={{ mr: "10px", ml: "10px" }} />{" "}
                  {getPlayerName(goalscorer)}
                </div>
              ))}
            </div>
            <div className="p05">
              {gameInfo.assistMakers.map((assistMakers, index) => (
                <div className="flex align-center p05" key={index}>
                  {getPlayerName(assistMakers)} <SoccerBoot />{" "}
                </div>
              ))}
            </div>
          </div>
          {(gameInfo.assistMakers.length > 0 || gameInfo.goalScorers.length > 0) && <Divider />}

          <div className="flex justify-between">
            <div className="p05">
              {gameInfo.yellowCards.map((playerKey, index) => (
                <div className="flex align-center p05" key={index}>
                  <CardIcon color={"yellow"} />
                  {getPlayerName(playerKey)}
                </div>
              ))}
            </div>
            <div className="p05">
              {gameInfo.redCards.map((playerKey, index) => (
                <div className="flex align-center p05" key={index}>
                  {getPlayerName(playerKey)} <CardIcon color={"red"} />{" "}
                </div>
              ))}
            </div>
          </div>
        </Card>
      )}
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel0a-content"
          id="panel0a-header"
        >
          <Typography>Score</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ScoreForm />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Goals, Assists & Kaarten</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {sortedPlayers.map((player) => {
            return playerCard(urlKey, player);
          })}
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>MVP</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {sortedPlayers.map((player) => {
            return playerCardSingleChoice(urlKey, player, "mvp");
          })}
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography>Lul van de match</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {sortedPlayers.map((player) => {
            return playerCardSingleChoice(urlKey, player, "lvp");
          })}
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default GameInfo;
