import { React, useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Paper,
  Typography,
  Toolbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  CircularProgress,
} from "@mui/material/";

import { SportsSoccer, EmojiEvents, Accessible } from "@mui/icons-material";
import SoccerBoot from "../customIcons/SoccerBoot";

import { visuallyHidden } from "@mui/utils";
import CardIcon from "../customIcons/CardIcon";

import * as firestore from "firebase/firestore";

import { db } from "../../firebase";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Speler",
    className: "sticky",
  },
  {
    id: "goals",
    numeric: true,
    disablePadding: true,
    label: <SportsSoccer />,
  },
  {
    id: "assists",
    numeric: true,
    disablePadding: true,
    label: <SoccerBoot />,
  },
  {
    id: "yellowCards",
    numeric: true,
    disablePadding: true,
    label: <CardIcon color={"yellow"} />,
  },
  {
    id: "redCards",
    numeric: true,
    disablePadding: true,
    label: <CardIcon color={"red"} />,
  },
  {
    id: "mvp",
    numeric: true,
    disablePadding: true,
    label: <EmojiEvents />,
  },
  {
    id: "lvp",
    numeric: true,
    disablePadding: true,
    label: <Accessible />,
  },
];

function EnhancedTableHead(props) {
  
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            sortDirection={orderBy === headCell.id ? order : false}
            className={headCell.className ? headCell.className : ""}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
};
const EnhancedTableToolbar = (props) => {
  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
      }}
    >
      <Typography
        sx={{ flex: "1 1 100%" }}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        Speler stats
      </Typography>
    </Toolbar>
  );
};

const Stats = ({ players }) => {
  const [loading, setLoading] = useState(true);
  const [games, setGames] = useState([]);
  const [orderGameStats, setOrderGameStats] = useState("asc");
  const [orderGameStatsBy, setOrderGameStatsBy] = useState("name");
  function getGamesFromFirebase() {
    const gamesFromFirebase = [];
    firestore
      .getDocs(firestore.collection(db, "games"))
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          gamesFromFirebase.push({ ...doc.data(), key: doc.id });
        });

        setGames(gamesFromFirebase);
        setLoading(false);
      });
  }
  function getGameInfoForPlayer(playerKey) {
    let mvp = 0;
    let lvp = 0;
    let goals = 0;
    let assists = 0;
    let yellowCards = 0;
    let redCards = 0;
    function getGameInfoDetail(gameDetail, playerKey){
      let counter = 0;
      gameDetail.forEach((entry) => {
          if (entry == playerKey) {
                counter++;
            }
        })
        return counter
    }
    games.forEach((game) => {   

      if (game["mvp"].includes(playerKey) ){
        mvp += 1
      }
      if (game["lvp"].includes(playerKey) ){
        lvp += 1
      }
      if (game["goalScorers"].includes(playerKey) ){
        goals = getGameInfoDetail(game["goalScorers"], playerKey)
      }
      if (game["assistMakers"].includes(playerKey)){
       assists = getGameInfoDetail(game["assistMakers"], playerKey)
      }
      if (game["yellowCards"].includes(playerKey)){
        yellowCards = getGameInfoDetail(game["yellowCards"], playerKey)
      }
      if (game["redCards"].includes(playerKey)){
        redCards = getGameInfoDetail(game["redCards"], playerKey)
      }

    });
    return {
      goals: goals,
      assists: assists,
      yellowCards: yellowCards,
      redCards: redCards,
      mvp: mvp,
      lvp: lvp,
    };
  }
  function createData(
    name,
    playerNumber,
    yellowCards,
    redCards,
    assists,
    goals,
    mvp,
    lvp
  ) {
    return {
      name,
      playerNumber,
      yellowCards,
      redCards,
      assists,
      goals,
      mvp,
      lvp,
    };
  }
  const rows = [];
  players.forEach((player) => {
    let playerGameInfo = getGameInfoForPlayer(player.key);
    rows.push(
      createData(
        player.name,
        player.playerNumber,
        playerGameInfo.yellowCards,
        playerGameInfo.redCards,
        playerGameInfo.assists,
        playerGameInfo.goals,
        playerGameInfo.mvp,
        playerGameInfo.lvp
      )
    );
  });

  const handleRequestSort = (event, property) => {
    const isAsc = orderGameStatsBy === property && orderGameStats === "asc";
    setOrderGameStats(isAsc ? "desc" : "asc");
    setOrderGameStatsBy(property);
  };
  useEffect(() => {
    getGamesFromFirebase();
  }, []);

  return (
    <div>
      <Box sx={{ width: "100%" }}>
        {loading && <CircularProgress />}

        <Paper sx={{ width: "100%", mb: 2 }}>
          <EnhancedTableToolbar />
          <TableContainer
            sx={{
              width: "87vw",
            }}
          >
            <Table
              sx={{
                width: "max-content",
              }}
              aria-labelledby="tableTitle"
            >
              <EnhancedTableHead
                order={orderGameStats}
                orderBy={orderGameStatsBy}
                onRequestSort={handleRequestSort}
              />
              <TableBody>
                {rows
                  .slice()
                  .sort(getComparator(orderGameStats, orderGameStatsBy))
                  .map((row, index) => {
                    return (
                      <TableRow
                        // hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.name}
                      >
                        <TableCell
                          component="th"
                          scope="row"
                          className="sticky"
                        >
                          <div className="flex align-center">{row.name}</div>
                        </TableCell>
                        <TableCell align="right">{row.goals}</TableCell>
                        <TableCell align="right">{row.assists}</TableCell>
                        <TableCell align="right">{row.yellowCards}</TableCell>
                        <TableCell align="right">{row.redCards}</TableCell>
                        <TableCell align="right">{row.mvp}</TableCell>
                        <TableCell align="right">{row.lvp}</TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          {players.length < 1 && <div>No players found. Please add 1</div>}
        </Paper>

        {/* way to update player with assists and goals */}
        {/* <div><Counter player={player} setPlayer2={setPlayer2} /></div> */}
      </Box>

      
    </div>
  );
};

export default Stats;
