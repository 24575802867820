import { React, useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { TextField, Button, Card, IconButton, CircularProgress} from "@mui/material/";
  import { getDoc, doc, updateDoc,} from "firebase/firestore";
  import { ArrowBackSharp, } from "@mui/icons-material";
  import { db } from "../../firebase";

function PlayerInfo({players, getPlayersFromFirebase}) {
    const navigate = useNavigate();

    const [formValues, setFormValues] = useState({
        amountPaidFor: 0,
        amountTotal: 0,
        totalSpent: 0,
      });
    let urlKey = useParams().key;
    const [playerInfo, setPlayerInfo] = useState({});
    const [loading, setLoading] = useState(true);
    async function getPlayerInfo(urlKey) {
        setLoading(true)
        const docRef = doc(db, "players", urlKey);
        const docSnap = await getDoc(docRef);
        setPlayerInfo(docSnap.data());
        console.log(docSnap.data())
        setLoading(false);
        getPlayersFromFirebase()
        setFormValues({
            amountPaidFor: docSnap.data().amountPaidFor,
            amountTotal: docSnap.data().amountTotal,
            totalSpent: docSnap.data().totalSpent,
          });
      }

      const handleInputChange = (e) => {
        const { name, value } = e.target;
        console.log(name, Number(value));
        setFormValues({
          ...formValues,
          [name]: Number(value),
        });
      };
      async function handleSubmit(event)  {
        setLoading(true)
        const playerDoc = doc(db, "players", urlKey);
        let newField={amountPaidFor: formValues.amountPaidFor,
            amountTotal: formValues.amountTotal ,
            totalSpent: formValues.totalSpent}

        event.preventDefault();

          await updateDoc(playerDoc, newField);

        getPlayerInfo(urlKey);
      };
      useEffect(() => {
        getPlayerInfo(urlKey);
      }, []);

    return (
        <div>
             <IconButton
        sx={{ justifyContent: "flex-start" }}
        className="w100"
        onClick={() => navigate(-1)}
      >
        <ArrowBackSharp />
      </IconButton>
      {loading && <CircularProgress />}

        {!loading && (
            <Card>
                <div className="text-center">

            {playerInfo.name}
                </div>

             <form className="text-center" onSubmit={handleSubmit}>
        <div>
            <TextField
              id="amountTotal"
              name="amountTotal"
              label={<span>Pinten gedronken ({playerInfo.amountTotal})</span>}
              type="number"
              value={formValues.amountTotal}
              onChange={handleInputChange}
              sx={{ m: 2 }}
              size="small"
            />
          <TextField
            id="amountPaidFor"
            name="amountPaidFor"
            label={<span>Pinten gegeven ({playerInfo.amountPaidFor})</span>}
            type="number"
            value={formValues.amountPaidFor}
            onChange={handleInputChange}
            sx={{ m: 2 }}
            size="small"
          />
          <TextField
            id="totalSpent"
            name="totalSpent"
            label={<span>Geld uitgegeven ({playerInfo.totalSpent/100})</span>}
            type="number"
            value={formValues.totalSpent}
            onChange={handleInputChange}
            sx={{ m: 2 }}
            size="small"
            helperText="In cent (bv €1,80 = 180) "

          />
        </div>

        <div>
          <Button variant="contained" type="submit">
            Save
          </Button>
        </div>
      </form>
      </Card>

)}
        </div>
        
    );
}

export default PlayerInfo;