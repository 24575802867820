import React, {useState} from "react";
import PropTypes from 'prop-types';
import {Box, Link, Table, TableBody,TextField, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, Button, Paper, Fab, Modal, Fade, Typography, Backdrop } from '@mui/material/';


import {Add} from '@mui/icons-material';

import { addDoc, collection } from "firebase/firestore"; 
import { db } from '../../firebase';

import { visuallyHidden } from '@mui/utils';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };
  



  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
  
  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  const headCells = [
    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      label: 'Speler',
    },
    {
      id: 'number',
      numeric: true,
      disablePadding: true,
      label: 'Nummer',
    }
  ];
  function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } =
      props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };
  
    return (
      <TableHead>
        <TableRow>

          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? 'right' : 'left'}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}

        </TableRow>
      </TableHead>
    );
  }
  EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
  };

  
  const PlayersList=({players, getPlayersFromFirebase})=>{
    const rows = [];
      players.forEach((player)=>{
          rows.push({name: player.name, number: player.playerNumber});
  
      })
      const defaultValues = {
        name: "",
        number: "",
      };
      const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormValues({
          ...formValues,
          [name]: value,
          
        });
      };
      const addNewItem = async () =>{
        await addDoc(collection(db, "players"), {
          name: formValues.name,
          playerNumber: formValues.number,
          amountTotal: 0,
          amountPaidFor: 0,
          totalSpent: 0
          });
          console.log(`${formValues.name} added`)

          getPlayersFromFirebase()
        }
      const handleSubmit = (event) => {
        event.preventDefault();
        addNewItem()
        handleClose()
      };
      const [formValues, setFormValues] = useState(defaultValues)

  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true)
    setFormValues(defaultValues)
   };
  const handleClose = () => setOpen(false);



  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };


    return (
        <Box sx={{ width: '100%' }}>
          {players.length > 0 &&
        <Paper sx={{ width: '100%', mb: 2 }}>
          <TableContainer>
            <Table
              aria-labelledby="tableTitle"
            >
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={players.length}
              />
              <TableBody >

            
                { players.slice().sort(getComparator(order, orderBy))
                  .map((player, index) => {
  
                    return (
                      <TableRow
                      // component={Link} to={`players/${player.key}`}
                        role="checkbox"
                        tabIndex={-1}
                        key={player.key}
                        // onClick={()=> console.log(player.key)}
                        // onClick={()=> window.location=`players/${player.key}`}
                      >
                       
                        <TableCell>
                                {player.name}
                        </TableCell>
                        <TableCell align="right">{player.playerNumber}</TableCell>


                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
          }
          {players.length < 1 && <div>
            No players found. Please add 1
          </div> }

        <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
          <form onSubmit={handleSubmit}>

            <Typography id="transition-modal-title" variant="h6" component="h2">
              Voeg speler toe
            </Typography>
            <div>
                <TextField
                id="name-input"
                name="name"
                label="Name"
                type="text"
                required
                value={formValues.name}
                onChange={handleInputChange}
                sx={{ m: 2 }}
                />
 
            </div>
            <div>
                <TextField 
                id="number-input"
                name="number"
                label="Number"
                type="number"
                value={Number(formValues.number)}
                onChange={handleInputChange}
                sx={{ m: 2 }}
                />
            </div>
            <div>
            <Button disabled={!formValues.name.length > 1 } variant="contained" type="submit">
                Save
            </Button>

            </div>
            </form>

          </Box>
        </Fade>
      </Modal>
        <Fab color="primary" aria-label="add" onClick={handleOpen} sx={{ position: 'fixed', right: '16px', bottom: '16px' }}>
            <Add />
        </Fab>
      </Box>
       
    )
};

export default PlayersList;