import { React, useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Paper,
  Typography,
  Toolbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material/";

import { SportsBar, Euro} from "@mui/icons-material";
import EmptyGlass from "../customIcons/EmptyGlass";

import { visuallyHidden } from "@mui/utils";



function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Speler",
    className: "sticky",
  },
  {
    id: "amountPaidFor",
    numeric: true,
    disablePadding: true,
    label: <SportsBar/>,
  },
  {
    id: "assists",
    numeric: true,
    disablePadding: true,
    label: <Euro fontSize="16px" />,
  },
  {
    id: "amountTotal",
    numeric: true,
    disablePadding: true,
    label: <EmptyGlass/>,
  },

];

function EnhancedTableHead(props) {
  
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            sortDirection={orderBy === headCell.id ? order : false}
            className={headCell.className ? headCell.className : ""}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
};
const EnhancedTableToolbar = (props) => {
  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
      }}
    >
      <Typography
        sx={{ flex: "1 1 100%" }}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        Andere stats
      </Typography>
    </Toolbar>
  );
};

const Stats = ({ players, getPlayersFromFirebase }) => {
  const [orderStats, setOrderStats] = useState("asc");
  const [orderStatsBy, setOrderStatsBy] = useState("name");


  const handleRequestSort = (event, property) => {
    const isAsc = orderStatsBy === property && orderStats === "asc";
    setOrderStats(isAsc ? "desc" : "asc");
    setOrderStatsBy(property);
  };
  useEffect(() => {
    getPlayersFromFirebase()
  }, []);

  return (
    <div>
      <Box sx={{ width: "100%" }}>

        <Paper sx={{ width: "100%", mb: 2 }}>
          <EnhancedTableToolbar />
          <TableContainer
            sx={{
              width: "87vw",
            }}
          >
            <Table
              sx={{
                width: "max-content",
              }}
              aria-labelledby="tableTitle"
            >
              <EnhancedTableHead
                order={orderStats}
                orderBy={orderStatsBy}
                onRequestSort={handleRequestSort}
              />
              <TableBody>
                {players
                  .slice()
                  .sort(getComparator(orderStats, orderStatsBy))
                  .map((player, index) => {
                    return (
                      <TableRow
                        // hover
                        role="checkbox"
                        tabIndex={-1}
                        key={player.name}
                      >
                        <TableCell
                          component="th"
                          scope="row"
                          className="sticky"
                        >
                          <div className="flex align-center">{player.name}</div>
                        </TableCell>
                        <TableCell align="right">{player.amountPaidFor}</TableCell>
                        <TableCell align="right">€{player.totalSpent/100}</TableCell>
                        <TableCell align="right">{player.amountTotal}</TableCell>

                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          {players.length < 1 && <div>No players found. Please add 1</div>}
        </Paper>

      </Box>

      
    </div>
  );
};

export default Stats;
