import React, {useState} from "react";
import "./player-list-item.css"
import { Card, CardActionArea ,Avatar,  Button, CardActions } from '@mui/material';
import {Link} from 'react-router-dom';
import Counter from "../counter/Counter";




const PlayerListItem=({player})=>{
    const [playerObj, setPlayerObj] = useState(player)
   

    return (
        <div>

        <Card className="player-list-item flex align-center">

            <div className="player-info flex align-center">
                <Avatar component={Link} to={`player/${player.key}`} query={player} alt="" src="" />

                <div>
                    {playerObj.name} 
                <p>
                </p>
                {<p >
                    <span className={`text-${playerObj.amountTotal > playerObj.amountPaidFor ? "red" : "green"}`}> {playerObj.amountTotal} | {playerObj.amountPaidFor} </span>  
                </p>}

                </div>
            </div>
            <div ><Counter player={playerObj} setPlayer2={setPlayerObj} /></div>
        </Card>
        </div>
    )
};

export default PlayerListItem;