import React from "react";
import { IconButton, Divider } from "@mui/material";
import { SportsBar, Sailing, LooksOne, Euro } from "@mui/icons-material/";
import { updateDoc, doc, increment } from "firebase/firestore";
import { db } from "../../firebase";
import EmptyGlass from "../customIcons/EmptyGlass";


const Counter = ({ player, setPlayer2: setPlayer }) => {
  async function handleUpdate(type, amount, money) {
    const playerDoc = doc(db, "players", player.key);
    let newField = null;
    switch (type) {
      case "drank":
        newField = { amountTotal: amount };
        setPlayer({ ...player, amountTotal: amount });
        break;
      case "paid":
        newField = { 'amountPaidFor': amount, 'totalSpent': increment(money) };
        setPlayer({ ...player, amountPaidFor: amount, totalSpent: money });
        break;
      default:
      // code block
    }
    await updateDoc(playerDoc, newField);
  }

  return (
    <div className="flex align-center">
        <div>
        <div className="flex justify-center">
          <EmptyGlass />
        </div>

            <div>
      <IconButton
        onClick={() => handleUpdate("drank", player.amountTotal + 1, 0)}
        aria-label="add"
      >
        <SportsBar />
      </IconButton>

            </div>
        </div>
      <Divider orientation="vertical" flexItem />
      <div>
        <div className="flex justify-center">
          <Euro fontSize="16px" />
        </div>
        <div>
          <IconButton
            onClick={() => handleUpdate("paid", player.amountPaidFor + 1,  180)}
            aria-label="add"
          >
            <LooksOne />
          </IconButton>
          <IconButton
            // onClick={() => console.log("paid", player.amountPaidFor + 11, player.totalSpent + 18)}
            onClick={() => handleUpdate("paid", player.amountPaidFor + 11, 1800)}
            aria-label="add"
          >
            <Sailing />
          </IconButton>
        </div>
      </div>
    </div>
  );
};

export default Counter;
