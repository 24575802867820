import firebase from 'firebase/compat/app';
import { getAnalytics } from "firebase/analytics";

import 'firebase/compat/auth';

import 'firebase/compat/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyAjFhx-Mu0l2n2KDPolFF5Ppl9RFq97XqY",
    authDomain: "vdpdrank.firebaseapp.com",
    projectId: "vdpdrank",
    storageBucket: "vdpdrank.appspot.com",
    messagingSenderId: "400762843030",
    appId: "1:400762843030:web:c3d40bd23df8afa3e9b7b7",
    measurementId: "G-WW6S9YQWJB"
};


const firebase2 = firebase.initializeApp(firebaseConfig);
const analytics = getAnalytics(firebase2);

const db = firebase2.firestore();

export { firebase2, db };