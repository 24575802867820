import { React, useEffect, useState } from "react";
import CardIcon from "../customIcons/CardIcon";
import "../Games/games.css";
import { Link as RouterLink } from "react-router-dom";

import * as firestore from "firebase/firestore";
import { addDoc, collection,  } from "firebase/firestore";

import { db } from "../../firebase";
import Moment from "moment";
import localization from "moment/locale/fr";

import {
  Box,
  Button,
  Fab,
  Modal,
  Fade,
  Typography,
  Backdrop,
  Card,
  CardActionArea,
  TextField,
  CircularProgress,
  CardActions 
} from "@mui/material/";
import { Add } from "@mui/icons-material";
import {} from "@mui/material";

Moment.locale("nl", localization);

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const defaultValues = {
  awayTeam: "",
  homeTeam: "",
  // date: Moment(),
};

function Games({ players }) {
  const [loading, setLoading] = useState(true);
  const [games, setGames] = useState([]);
  const [formValues, setFormValues] = useState(defaultValues);
  const [open, setOpen] = useState(false);
  const gamesFromFirebase = [];
  function getGamesFromFirebase() {
    firestore
      .getDocs(firestore.collection(db, "games"))
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          gamesFromFirebase.push({ ...doc.data(), key: doc.id });
        });

        setGames(gamesFromFirebase);
        setLoading(false);
      });
  }

  function gameCard({ game }) {
    return (
      <Card className=" flex align-center margin1-0" key={game.key}>
        <CardActionArea component={RouterLink} to={`${game.key}`} state= {game}  >
          <div className=" flex w100">
            <div className="game-info w100">

              <div className="game-score">
                <div className="w100">{game.homeTeam}</div>
                <div className="w100">
                  {game.homeScore} - {game.awayScore}
                </div>
                <div className="w100">{game.awayTeam}</div>
              </div>
              <div className="game-details">
                {game.yellowCards.length > 0 && (
                  <CardIcon color={"yellow"} amount={game.yellowCards.length} />
                )}
                {game.redCards.length > 0 && (
                  <CardIcon color={"red"} amount={game.redCards.length} />
                )}
              </div>
            </div>
          </div>
        </CardActionArea>
        
      </Card>
    );
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };
  const addNewItem = async () => {
    await addDoc(collection(db, "games"), {
      assistMakers: [],
      goalScorers: [],
      awayScore: null,
      awayTeam: formValues.awayTeam,
      homeScore: null,
      homeTeam: formValues.homeTeam,
    //   date: formValues.date,
      redCards: [],
      yellowCards: [],
      mvp: "",
      lvp: "",
    });
    console.log(`Game added`);

    getGamesFromFirebase();
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    addNewItem();
    handleClose();
  };
  const handleOpen = () => {
    setOpen(true);
    setFormValues(defaultValues);
  };
  const handleClose = () => setOpen(false);

  useEffect(() => {
    getGamesFromFirebase();
  }, []);
  return (
    <div>
        {loading && <CircularProgress />}
      {games.map((game) => gameCard({ game }))}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <form onSubmit={handleSubmit}>
              <Typography
                id="transition-modal-title"
                variant="h6"
                component="h2"
              >
                Voeg wedstrijd toe
              </Typography>
              <div>
                <TextField
                  id="homeTeam-input"
                  name="homeTeam"
                  label="Thuisploeg"
                  type="text"
                  required
                  value={formValues.homeTeam}
                  onChange={handleInputChange}
                  sx={{ m: 2 }}
                />
              </div>
              <div>
                <TextField
                  id="awayTeam-input"
                  name="awayTeam"
                  label="Uitploeg"
                  type="text"
                  required
                  value={formValues.awayTeam}
                  onChange={handleInputChange}
                  sx={{ m: 2 }}
                />
              </div>
             
              <div>
                <Button
                  disabled={
                    !formValues.homeTeam.length > 1 ||
                    !formValues.awayTeam.length > 1
                  }
                  variant="contained"
                  type="submit"
                >
                  Save
                </Button>
              </div>
            </form>
          </Box>
        </Fade>
      </Modal>

        <Fab
          color="primary"
          aria-label="add"
          onClick={handleOpen}
          sx={{ position: "fixed", right: "16px", bottom: "16px" }}
        >
          <Add />
        </Fab>
    </div>
  );
}

export default Games;
